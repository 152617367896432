@use '../../../styles/colors';
@use '../../../styles/lengths';

.container {
  width: 100%;
  height: 100%;

  .pageError {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: lengths.$pageErrorMinSize;
    height: 100%;
    min-height: lengths.$pageErrorMinSize;
    background: colors.$pageErrorBackground;
  }
}

@media only screen and (max-width: lengths.$pageErrorMinSize) {
  .container {
    min-width: unset;
    min-height: unset;
    overflow: auto;
  }
}

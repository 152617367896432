@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/lengths';
@use '../../../styles/typography';

.container {
  display: flex;
  flex-direction: column;
  gap: lengths.$errorContentGap;
  align-items: center;
  justify-content: center;
  color: variables.$white;

  .imageError {
    width: lengths.$errorContentImageWidth;
    height: lengths.$errorContentImageHeight;
    background-image: url('../../../assets/images/error_animation.gif');
  }

  .status {
    font: typography.$ENGPageError;
    text-transform: uppercase;
  }

  .detail {
    font: typography.$JPExtraLargeLineHeight36;
    text-align: center;
  }
}

// TODO: remove this file

$fontFamily: 'Barlow', 'Noto Sans JP', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

// Option fonts size
$fontSizeSmall: 12px;
$fontSizeMedium: 14px;
$fontSizeLarge: 16px;
$fontSizeExtraLarge: 24px;

// Option font weight

$fontWeightNormal: 400;

// for examples
$exampleFontSizeSmall: 12px;
$exampleFontSizeMedium: 14px;
$exampleFontSizeLarge: 16px;
$exampleFontSizeExtraLarge: 24px;
$exampleFontFamily: 'Nunito Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$exampleFontWeight: 700;

// fontSize
$fontSizeSmall: 12px;
$fontSizeMedium: 14px;
$fontSizeLarge: 16px;
$fontSizeExtraLarge: 24px;

// fontWeight
$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

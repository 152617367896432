@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../styles/typography';

.Toastify__toast-body {
  font: typography.$JPLabelSmallBold;
  color: variables.$dark-blue-110;

  .Toastify__toast-icon {
    width: 24px;
    height: 24px;
  }
}

@use './typography';
@use 'ress';

// NOTE: #__next の箇所は自律応対 (Klleon 利用時) に body 直下に要素が生成されるため追加
/* stylelint-disable-next-line selector-id-pattern */
html,
body,
body > div:first-child,
body > #__next {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: typography.$fontFamily;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
